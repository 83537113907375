
import React, { useEffect } from "react";
import useSetState from '../../utils/useSetState';
import fetch_data from './utils/fetch_data';
import Artist from '../Artist';
import queryString from 'query-string';


const ArtistPageContainer = ({ location }) => {

  const [state, setState] = useSetState({});

  useEffect(() => {     
    const queryObj = queryString.parse(location.search);
    const id = queryObj['_id']
    fetch_data({ setState, id});
  }, [])

  return (
   <Artist data={state}/>
  );
};

export default ArtistPageContainer;
