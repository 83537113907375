
import React from "react";
import Navbar from "../lib/components/Navbar/Navbar";
import Grid from "../lib/components/Grid/Grid";
import CenterContainer from "../lib/components/CenterContainer/CenterContainer";
import GridSkeletons from "../lib/components/GridSkeletons/GridSkeletons";
import CardVertical from "../lib/components/CardVertical/CardVertical";
import Breadcrumbs from "../lib/components/Breadcrumbs/Breadcrumbs";
import Avatar from "../lib/components/Avatar/Avatar";
import PageHeader from "../lib/components/PageHeader/PageHeader";
import { Helmet } from "react-helmet"


const Artist = ({ data }) => {

  const { deckItems } = data;
  const artist = data && data.artist;

  return (
    <>
     <Helmet>
        <title>Artist</title>
      </Helmet>
      <Navbar/>
      <CenterContainer>
          <PageHeader>
            {deckItems && artist ? 
              <>
                <div>
                  <Breadcrumbs crumbsArr={[{title: 'Artists', to:'/#artists'}, {title: 'Artist'}]} /> 
                  <h1 className='breadCrumbTitle'>{artist.name}</h1>
                  <p style={{fontSize: '13px', marginTop: '8px', color: '#c4c4c4', maxWidth: '400px'}}>
                    {artist.bio}
                  </p>
                </div>
                
                <Avatar imgUrl={artist.avatarImgUrl}/>
              </>
            : null }
           </PageHeader>
            <Grid>
                { deckItems ? deckItems.map((item) => {
                    return (
                        <CardVertical 
                            key={item._id}
                            cardLink={`/deckItem?_id=${item._id}&by=${artist.name}`}
                            title={item.title} 
                            imgUrl={item.imgUrl} 
                            description={<>by <span style={{textDecoration: 'underline'}}>{artist.name}</span></>}
                        />
                    )
                }) : <GridSkeletons/>}
            </Grid>
            </CenterContainer>
        <br/>
        <br/>
        <br/>
       
    </>
  )
}

export default Artist;







