
import { api_base_url } from '../../../../global_data';
import axios from "axios";


const fetch_data = async ({ setState, id }) => {
    
    const deckItemsRes = await axios({
        url: `${api_base_url}/deckItems/filter`,
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=utf-8"
        },
        data: JSON.stringify({
            filterObj: {
                artist: id,
                public: true,
                // featured: true,
                isCopy: { $not: { $eq: true } }
            },
            sortObj: {
                sortBy: 'createdAt',
                sortType: 'desc'
            }
        })
    });
    
    const artistRes = await axios({
        url: `${api_base_url}/artists/${id}`,
        method: 'GET'
    });

    const deckItems = deckItemsRes.data;
    const artist = artistRes.data;

    setState({deckItems, artist});

}

export default fetch_data;