
import React from "react";
import ArtistPageContainer from '../Artist/containers/ArtistPageContainer';


const ArtistPage = ({ location }) => {

  return (
    <ArtistPageContainer location={location}/>
  )
}

export default ArtistPage;
